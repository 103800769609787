var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ct-table",
    _vm._g(
      _vm._b(
        {
          ref: "table",
          attrs: {
            loaded: _vm.loaded,
            fields: _vm.tableDefinition.columns,
            items: _vm.records,
            "sticky-header": _vm.stickyHeader,
            responsive: _vm.responsive,
            "total-records": _vm.totalRecords,
            "sort-by": _vm.ctx.sortBy,
            "sort-desc": _vm.ctx.sortDesc,
            "is-select-all-checked": _vm.isSelectAllChecked,
            "all-elements-selectable": _vm.shouldEnableCheckbox(_vm.records),
            "is-paginated": _vm.isPaginated ? _vm.totalRecords >= 15 : false,
            "no-local-sorting": _vm.noLocalSorting,
          },
          on: {
            "update:sortBy": function ($event) {
              return _vm.$set(_vm.ctx, "sortBy", $event)
            },
            "update:sort-by": function ($event) {
              return _vm.$set(_vm.ctx, "sortBy", $event)
            },
            "update:sortDesc": function ($event) {
              return _vm.$set(_vm.ctx, "sortDesc", $event)
            },
            "update:sort-desc": function ($event) {
              return _vm.$set(_vm.ctx, "sortDesc", $event)
            },
            "pagination-changed": _vm.reload,
            "sort-changed": _vm.sortChanged,
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function (_, name) {
                return {
                  key: name,
                  fn: function (slotData) {
                    return [_vm._t(name, null, null, slotData)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "ct-table",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }