<template>
  <ct-table
    ref="table"
    :loaded="loaded"
    :fields="tableDefinition.columns"
    :items="records"
    :sticky-header="stickyHeader"
    :responsive="responsive"
    :total-records="totalRecords"
    :sort-by.sync="ctx.sortBy"
    :sort-desc.sync="ctx.sortDesc"
    :is-select-all-checked="isSelectAllChecked"
    :all-elements-selectable="shouldEnableCheckbox(records)"
    :is-paginated="isPaginated ? (totalRecords >= 15) : false"
    :no-local-sorting="noLocalSorting"
    v-bind="$attrs"
    v-on="$listeners"
    @pagination-changed="reload"
    @sort-changed="sortChanged"
  >
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </ct-table>
</template>

<script>
/*

# Ajax Table
This is a wrapper for the CtTable component with some additional functionality. This table processes
its own AJAX request for paginated data and has the ability to pass filters, order-by info, and
scope-based queries through to the API. All parameters, slots, and events are passed through this,
as if they were going directly to/from the grandparent/grandchild components. To access the raw
data, try listening on @loaded.

## Sorting
Column sorting can be enabled per-column within the column definition:
  { key: 'company_name', sortable: true },

If the string used as the 'key' does not match up with the column name in the database, "sortAs" can
be used to specify the sorting column.
  { key: 'jurisdiction', sortable: true, sortAs: 'jurisdictions.state_province_region' },

## Ordering
Set the default order column and direction using the defaultOrderBy and defaultOrderDirection
on the tableDefinition parameter (see example below).

## Complete usage example:
{
  columns: [
    { key: 'company', label: 'Company', sortable: true, sortAs: 'company.name' },
    { key: 'jurisdiction', label: 'Jurisdiction', sortable: true, sortAs: 'registration.jurisdiction.state_province_region' },
  ],
  defaultOrderBy: 'company'
  defaultOrderDirection: 'asc',
  url: 'client/order_items/pending_filings',
  parameters: {
    search_name: 'search_pending_filings_page',
    query: this.query,
  },
}

*/

import http from '../../http'
import CtTable from './CtTable'
import qs from 'qs'

export default {
  name: 'AjaxTable',

  components: { CtTable },
  props: {
    items: {
      type: Boolean,
      default: false,
    },
    dataTransformFunction: {
      type: [Boolean, Function],
      default: false,
    },
    shouldEnableCheckbox: {
      type: Function,
      default: function() {
        return true
      },
    },
    tableDefinition: {
      type: Object,
      required: true,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: [String, Boolean],
      default: '570px',
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    noLocalSorting:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    let sortDesc = true
    let orderColumn = null

    // Set up defaults for column ordering.
    if (this.tableDefinition && this.tableDefinition.columns.length > 0) {
      if (this.tableDefinition.defaultOrderBy) {
        orderColumn = this.tableDefinition.defaultOrderBy
      } else if (this.tableDefinition.columns.some(element => element.key === 'created_at')) {
        orderColumn = 'created_at'
      } else {
        // Since there is no created_at column, the developer really *should* specify default sort.
        console.error('Table Warning: A defaultOrderBy column should be specified.')
        orderColumn = this.tableDefinition.columns[0].key
      }

      if (this.tableDefinition.defaultOrderDirection) {
        sortDesc = this.tableDefinition.defaultOrderDirection === 'desc'
      }
    }

    return {
      loaded: false,
      orderBy: orderColumn,
      sortDesc: sortDesc,
      totalRecords: 0,
      records: this.items,
      isSelectAllChecked: false,
      ctx: {
        currentPage: 0,
        perPage: 25,
        sortBy: orderColumn,
        sortDesc: sortDesc,
        apiUrl: this.tableDefinition.url,
      },
    }
  },
  watch: {
    tableDefinition() {
      this.reload()
    },
  },
  mounted() {
    this.reload()
  },
  methods: {
    async reload() {
      this.loaded = false

      const limit = this.tableDefinition.limitOverride ? this.tableDefinition.limitOverride : this.$refs.table.resultsPerPage
      this.ctx.currentPage = this.$refs.table.currentPage
      const offset = limit * (this.ctx.currentPage - 1)
      const response = await http.get(this.tableDefinition.url, {
        params: {
          ...this.tableDefinition.parameters,
          limit: limit,
          offset: offset,
          include_count: true,
          generic_search: this.tableDefinition.generic_search,
          order_by: this.ctx.sortBy,
          order_direction: this.ctx.sortDesc ? 'desc' : 'asc',
        },

        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'brackets',
            encode: false,
          })
        },
      })

      // This is a count of all relevant records (not the length of the paginated response).
      this.totalRecords = response.data.count
      let records = response.data.result || []

      if (records.length > 0) {
        records = records.map(r => Object.assign(r, { _showDetails: false }))
      }

      if (this.dataTransformFunction) {
        records = this.dataTransformFunction(records, this.ctx)
      }

      this.shouldEnableCheckbox

      this.records = records.length ? this.companyNameEncoding(records) : records

      this.$emit('loaded', this.records)
      this.isSelectAllChecked = false
      this.loaded = true
    },
    sortChanged(tableContext) {
      const clickedColumn = this.tableDefinition.columns.find(
        element => element.sortable && element.key === tableContext.sortBy
      )

      if (clickedColumn) {
        this.ctx.sortBy = clickedColumn.sortAs || tableContext.sortBy
        this.ctx.sortDesc = !this.ctx.sortDesc;
      }

      this.reload()
    },
    companyNameEncoding(records) {
      for (let record of records) {
        if ('company_name' in record) {
          record['company_name'] = record['company_name'].replace(/&amp;/g, '&')
        }
      }

      return records
    },
  },
}
</script>
